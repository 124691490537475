import styled from '@emotion/styled';
import { Wrapper } from '../../components';

export const Hero = styled.section`
  margin-bottom: 6rem;
  background-color: ${props => props.theme.colors.greyLight};
  background-position: center;
  background-size: cover;
  display: flex;
  position: relative;
  align-items: center;
  align-content: center;
  z-index: 0;
`;

export const HeroBrightner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

export const HeroThumb = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${props => props.theme.colors.primary};
  
  //   @media (max-width: ${props => props.theme.breakpoints.m}) {
  //     width: 100px!important;
  //     height: 100px!important;
  //     bottom: -50px;
  // }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
      width: 100px!important;
      height: 100px!important;
      bottom: -50px;
  }
`;

export const HeroInner = styled.div`
  padding-top: 8rem;
  padding-bottom: 8rem;
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.footer};
  z-index: 2;

  h1 {
    margin-bottom: 0;
    color: ${props => props.theme.colors.footer};
//     text-transform: uppercase;
  }

  p {
    max-width: 650px;
    display: inline-block;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${props => props.theme.colors.footer};
    font-style: normal;
    font-weight: bold;

    &.author {
      text-transform: capitalize;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`;

export const HeadWrapper = styled.div`
  width: 100%;
  max-width: 976px;
  display: inline-block;
  color: ${props => props.theme.colors.footer};
  font-size: 1.25rem;
  background-color: rgba(255,255,255,0.55);
  padding: 3rem 0.8rem;
`;

export const Headline = styled.div`
  color: ${props => props.theme.colors.footer};
  font-size: 1.25rem;
`;

export const StyledWrapper = styled(Wrapper)`
    padding: 4rem 0;
  blockquote {
    font-size: 1.2rem;

    width: 80%;
    display: block;
    margin: 1.8rem auto;
    color: ${props => props.theme.colors.primary};

    p {
      font-style: italic;
      font-weight: bold;
      margin-bottom: 0;
    }

    p::before,
    p::after {
      content: '“';
      font-family: Georgia;
      font-size: 4rem;
      margin: -1.4rem 0 0 -3rem;
      position: absolute;
      opacity: 0.5;
    }

    p::after {
      content: '”';
      margin: -0.6rem -5rem 0 0;
    }

    cite {
      font-size: 1.5rem;
    }
  }
  a{

    &.button{
    width: fit-content;
      text-align: center;
      display: block!important;
      margin: 0 auto!important;
      font-size: 0.7rem;
      line-height: 2.6rem;
      font-weight: bold;
      text-transform: uppercase;
      transition: all 0.5s;
      display: inline-flex;
      position: relative;
      padding-left: 13px;
      padding-right: 13px;
      border-radius: 3px;
      color: ${props => props.theme.colors.primary};
      border: 2px solid ${props => props.theme.colors.primary};
      transition: all 0.3s;
      margin: 0 auto 1rem auto;
      text-align: center;

        &:hover{
          color: ${props => props.theme.colors.white}!important;
          background-color: ${props => props.theme.colors.primary};
        }
      }
  }
`;

