import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { Layout, Wrapper, SliceZone, SEO, Header, ListingCard } from '../../components';
import website from '../../../config/website';
import { Hero, HeroInner, Headline, HeroBrightner, StyledWrapper, HeadWrapper, HeroThumb } from './Elements';
import Img from "gatsby-image";

const Offre = ({ data: { prismicOffre, posts }, location }) => {
  const { data } = prismicOffre;

  return (
    <Layout customSEO>
      <SEO
        title={`${data.title.text} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={data.shortdesc.text}
        banner={data.main.full.url}
        node={prismicOffre}
        article
      />
      <Header light />
      <Hero style={{ backgroundImage: `url(${data.main.full.url})` }}>
        {/*<HeroBrightner />*/}
        <HeroInner>
          <HeadWrapper>
          <Headline dangerouslySetInnerHTML={{ __html: data.title.html }} />
          {/*<div dangerouslySetInnerHTML={{ __html: data.shortdesc.html }} />*/}
        </HeadWrapper>
          <HeroThumb style={{ width: '200px', height: '200px' }}>
            <Img fluid={data.thumb.min.localFile.childImageSharp.fluid} alt={data.thumb.min.alt}/>
          </HeroThumb>
        </HeroInner>
      </Hero>
        <SliceZone allSlices={data.body} />
      {/*<StyledWrapper>*/}
      {/*  <Link className="button" to="/contact">Nous contacter</Link>*/}
      {/*  <h3 style={{ marginTop: '4rem' }}>Nos autres offres</h3>*/}
      {/*  <ListingCard posts={posts.edges} />*/}
      {/*</StyledWrapper>*/}
    </Layout>
  );
};

export default Offre;

Offre.propTypes = {
  data: PropTypes.shape({
    prismicOffre: PropTypes.object.isRequired,
    posts: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query OffreBySlug($uid: String!) {
      prismicOffre(uid: { eq: $uid }) {
      uid
      data {
        title {
          html
          text
        }
        shortdesc {
          text
          html
        }
        main {
          full {
            localFile {
              childImageSharp {
                resize(width: 1200, height: 675, quality: 80) {
                  src
                }
              }
            }
            url
          }
        }
          thumb {
              min {
                  localFile {
                      childImageSharp {
                          fluid(maxWidth: 300, quality: 90) {
                              ...GatsbyImageSharpFluid_withWebp
                          }
                      }
                  }
              }
          }  

        body {
          ... on PrismicOffreBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicOffreBodyQuote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
              author
            }
          }
#          ... on PrismicOffreBodyCodeBlock {
#            slice_type
#            id
#            primary {
#              code_block {
#                html
#              }
#            }
#          }
          ... on PrismicOffreBodyVideo {
            slice_type
            id
            primary {
              video {
                html
              }
            }
          }
          ... on PrismicOffreBodyListeDeProfiles {
              id
              items {
                  person_selected {
                      uid
                      document {
                          data {
                              name
                              surname
                              description {
                                  html
                                  text
                              }
                              image {
                                  thumb {
                                      localFile {
                                          childImageSharp {
                                              fluid(maxWidth: 400, quality: 90) {
                                                  ...GatsbyImageSharpFluid_withWebp
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                          uid
                      }
                  }
              }
              slice_type
              primary {
                  selectedtext {
                      html
                      text
                  }
                  name{
                      html
                      text
                  }
              }
          }
          ... on PrismicOffreBodyListeImageTexte {
              id
              slice_type
              primary {
                  title {
                      html
                      text
                  }
              }
              items {
                  image {
                      mini {
                          localFile {
                              childImageSharp {
                                  fluid(maxWidth: 200, quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                  }
                  text {
                      html
                  }
                  title {
                      html
                  }
              }
          }
          ... on PrismicOffreBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(limit: 3, sort: { fields: [data___date], order: DESC }, filter: { uid: { ne: $uid } }) {
      edges {
        node {
          uid
          first_publication_date
          last_publication_date
          data {
            title {
              text
            }
            shortdesc {
              text
            }
            main {
              full {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            date(formatString: "DD.MM.YYYY")
            categorie {
              document {
                uid
                data {
                  name
                  color
                }
              }
            }
            authors {
              author {
                id
                uid
                document {
                  data {
                    name
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
